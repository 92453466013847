import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  /* console.log(message);
  console.log(logLevel); */
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({

    auth: {
      clientId: environment.shortenerService.clientId,
      authority: environment.shortenerService.authority,
      redirectUri: environment.shortenerService.redirectUri,
      postLogoutRedirectUri: environment.shortenerService.logoutRedirectUri
    },

    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    
    system: {
      allowNativeBroker: false,
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.shortenerService.scopes /* ['user.read'] */
    }/* ,
    loginFailedRoute: '/login-failed' */
  };
}

export function UsersMSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.shortenerService.scopes
    }
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(environment.shortenerService.url , environment.shortenerService.scopes);//shortener config
  
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}