import { Component, HostListener } from '@angular/core';
import { AuthComponent } from './auth/auth.component';
import { Store } from '@ngrx/store';
import { AppState } from './models/appState';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthComponent]
})
export class AppComponent {
  title = 'shortener';

  isIframe :                                    boolean = false;
  logged :                                      boolean = false;
  userActivity:                                 ReturnType<typeof setTimeout>;
  userInactive:                                 Subject<string> = new Subject();

  constructor(
    private authComponent :                     AuthComponent,
    // private store:                              Store<AppState>, 
    // private professionalService:                ProfessionalService,
    // private componentToSpinnerService:          ComponentToSpinnerService,
    // private handleHTTPerrorsService:            HandleHTTPerrorsService
  ){
    this.setTimeout();
  };

  ngOnInit(): void {
    this.FillProfessionalInfo();
  }

  FillProfessionalInfo():void{
    /* this.store.select(appState => appState.User).subscribe((user) => {
      const {username} = user;
      if(username){
        
        if(!user.userRole){
          this.professionalService.LoadUserByEmail(username).subscribe((res)=>{
            
            const {role , clinics , documentNumber , phoneNumber , gender , active , id} = res;

            let info = {
              ...user ,userRole:role ,clinics : clinics , documentNumber: documentNumber ,
              phoneNumber : phoneNumber , gender : gender , active : active , professionalLoggedId : id
            };
            
            this.store.dispatch(addUser(info));

          },(error: HttpErrorResponse) => {
            this.componentToSpinnerService.sendMessage('hide');
            this.handleHTTPerrorsService.HandleHTTPerrors( error );
          })
        }
      }
    }) */
  }

  RecibeFrame(iframe:boolean):void{
    this.isIframe = iframe;
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.authComponent.Logout(),900000);
  }

  isLogged(e:boolean):void{
    this.logged = e;
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
