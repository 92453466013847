import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  
  {
    path: '', redirectTo:'shortener' , pathMatch:'full'
  },
  {
    path:'shortener',
    loadChildren:()=>import('./shortener/shortener.module').then(m=>m.ShortenerModule),
    canActivate:[MsalGuard]
  },
  {
    path:'**',
    loadChildren:()=>import('./redirect/redirect.module').then(m=>m.RedirectModule),
    canActivate:[MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
