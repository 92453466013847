import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LongPost, Short } from 'src/app/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShortenerService {

  constructor(
    private http :                                     HttpClient
  ) { }

  SendLink( longInfo : LongPost ) :Observable<Short> {
    const { longUrl , userId } = longInfo || {};
    return this.http.post<Short>(`${environment.shortenerService.url}short?LongUrl=${longUrl}&UserId=${userId}` , {});
  }

  GetShorts():Observable<Short[]>{
   
    return this.http.get<Short[]>(`${environment.shortenerService.url}short`);
  }

  GetShorUrl( shortCode:string | undefined ){
    return this.http.get(`${environment.shortenerService.url}short/${shortCode}`)
  }
}
