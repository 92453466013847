import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of , EMPTY } from 'rxjs';
import { catchError, mergeMap , exhaustMap, map, switchMap, concatMap } from 'rxjs/operators';
import { ShortenerService } from 'src/app/shortener/services';
import { addShort, addShortFailure, addShortSuccess, getShorts, getShortsFailure, getShortsSuccess } from '../../actions/users/shorts/shorts.actions';
import { Short } from 'src/app/models';

@Injectable()

export class shortsEffects {

    constructor( 
        private action$ : Actions,
        private shortenerService : ShortenerService 
    ){}

    loadShorts$ = createEffect(() => {
        return this.action$

        .pipe(
            ofType(getShorts),
            switchMap(action =>
                {
                    return this.shortenerService.GetShorts()

                    .pipe(

                        map((shorts) => {

                            console.log(shorts);

                            return getShortsSuccess ({ shorts })
                        }),
                        
                        catchError(error => {

                            return of( getShortsFailure({ error : error }) )

                        })
                    )

                }
            )
        )
    })

    /* addShort$ = createEffect(() => {
        return this.action$.pipe(
            ofType( addShort ),
            concatMap( (addShort) => {
                
                return this.shortenerService.SendLink( addShort.short )
                .pipe(
                    map(res => {
                        if(res.shortUrl){
                            return this.shortenerService.GetShorUrl( res.shortUrl )
                            .pipe(
                                map(( shortUrlRes ) => {
                                    const myShort = { ...res , shortUri : shortUrlRes }
                                    return addShortSuccess({ myShort })
                                })
                            )
                        } else {
                            const myShort = res;
                            return  addShortSuccess({ myShort })
                        }
                    }),
                    catchError(error => {

                        return of( addShortFailure({ error : error }) )

                    })
                )

                
            })
        );
    }); */

    addShort$ = createEffect(() =>

        this.action$.pipe(
            ofType(addShort),
            mergeMap(( action ) => {

                return this.shortenerService.SendLink( action.short )
                .pipe(

                    map(( short ) =>{

                        return addShortSuccess({ short })

                    }),
                    catchError(error => {

                        return of( addShortFailure({ error : error }) )

                    })
                )
            })
        )
    );

        

        /* this.action$.pipe(
            ofType(addShort),
            mergeMap(( action ) => {

                return this.shortenerService.SendLink( action.short )
                .pipe(

                    map(( short ) =>{
                        console.log( short );
                        
                        const { shortUrl } = short || {};

                        

                        
                        return addShortSuccess({ short })

                    }),
                    catchError(error => {

                        return of( addShortFailure({ error : error }) )

                    })
                )
            })
        ) */
    
}
