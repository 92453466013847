/* models */
import { UserInfo } from 'src/app/models';
/* ngrx */
import { createReducer , on } from '@ngrx/store';
import { addUser, removeUser } from 'src/app/store/actions/users/user.actions';

export interface userState {
    User: UserInfo
}

export const initialUserEntries : UserInfo = {} ;

export const UserReducer = createReducer(
    initialUserEntries,

    on(addUser , (entries , User)=>{
        const entriesClone : UserInfo = User;
        return entriesClone;
    }),
    
    on(removeUser , (EmptyObject) => {
        const entriesClone : UserInfo = EmptyObject;
        return entriesClone;
    })
) 