import { Component , Inject, OnDestroy , EventEmitter , Output } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus, RedirectRequest} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { faEye, fas ,faLock, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
// import { AppState } from 'src/app/models/prescriptions/interfaces/appState';
import { environment } from 'src/environments/environment';
import { AppState } from '../models/appState';
import { addUser } from '../store/actions/users/user.actions';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnDestroy {
  isIframe:                           boolean = false;
  loginDisplay:                       boolean = false;
  username ! :                        string;
  faEye:                              IconDefinition = faEye;
  faLock:                             IconDefinition = faLock;
  user_name :                         string = "";
  userDestroyed$:                     Subject<void> = new Subject<void>();
  private readonly _destroying$ =     new Subject<string>();

  @Output() iframe :                  EventEmitter<boolean> = new EventEmitter;
  @Output() isLogged :                EventEmitter<boolean> = new EventEmitter;

  constructor(
    private store:                    Store<AppState>,
    private authService:              MsalService , 
    private msalBroadcastService:     MsalBroadcastService,
    private router:                   Router,
    library:                          FaIconLibrary,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ){
    library.addIconPacks(fas);
    library.addIcons(faEye);
    library.addIcons(faLock);
  }

  ngOnInit(): void {
   
    if(window.localStorage.getItem('idToken')){
      this.loginDisplay=true;
    };
    let userInfo: AccountInfo = this.authService.instance.getAllAccounts()[0];
    
    this.store.dispatch(addUser(userInfo));
    
    this.store.select(appState => appState.User)
    .pipe( takeUntil( this.userDestroyed$ ) )
    .subscribe((user) => {
      const { name } = user || {};
      if(name)this.user_name = name.split(' ')[0];
    })
    this.CheckLogin();
  }

  CheckLogin(){
    console.log('checkamos login');
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.msalSubject$.pipe(

      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    
    ).subscribe((result: EventMessage) => {

      this.store.dispatch(addUser((result.payload as any).account));
      
      const idToken = (result.payload as any).idToken;
      const accessToken = (result.payload as any).accessToken;
      const user_id = (result.payload as any).account.localAccountId;
      
      window.localStorage.setItem('idToken',idToken);
      window.localStorage.setItem('accessToken',accessToken);
      window.localStorage.setItem('user_id',user_id);
      
      this.isLogged.emit(true);
      this.setLoginDisplay();
    });
    this.iframe.emit(this.isIframe);
  }

  Login() {
    this.msalBroadcastService.inProgress$.pipe(
      filter((status : InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(()=>{
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    })
  }
 
  Logout() {
    /* LIMPIAMOS EL STATE */
    // this.store.dispatch(removeUser({}));
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('state');
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('user_id');
    this.authService.logoutRedirect({
      // postLogoutRedirectUri: environment.logoutRedirectUri
    });
  }

  GoToPrescriptions():void{
    this.router.navigate(['prescriptions']);
  }
  
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    // this._destroying$.next(undefined);
    this._destroying$.complete();
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
  }
}
