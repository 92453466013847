<div *ngIf="loginDisplay" class="auth_main-container" >

    <div class="auth-left_banner_container" >
        <!-- <div (click)="GoToPrescriptions()" class="auth_main-img" >
            <img class="app_documents-logo" src="../assets/logos/logo_baviera_optim.png" alt="logo clinica baviera">
        </div> -->
    </div>

    <div class="auth_main-login_logout_container" >
        <div class="auth_main-bienvenido_container" >
            <span class="auth_main-bienvenido_span" >{{user_name}}</span>
        </div>

        <div  (click)="Logout()" class="auth_main-container_logout_container" >
            <fa-icon class="auth_main-lock_icon" [icon]="faLock"></fa-icon>
            <span  >Logout</span>
        </div>
    </div>
</div>
