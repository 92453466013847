/* ngrx */
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { hydrationMetaReducer } from "src/app/store/hydratation.reducer";
import { AppState } from "src/app/models/appState";
import { UserReducer } from "src/app/store/reducers/users/user.reducers";
import { ShortsReducer } from "./reducers/shorts/shorts.reducers";




export const reducers : ActionReducerMap<AppState> = {
  User : UserReducer,
  Shorts: ShortsReducer
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    
 
    return reducer(state, action);
  };
}

export const metaReducers : MetaReducer[] = [hydrationMetaReducer]