import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatDatesService {

  constructor() { }

  FormatPrescriptionDateYearMonthDay(date:string):string{
    let dateObj  = new Date(date);
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  GetOnlyTime(date : Date):string{

    const myDate = new Date(date);
    const minutes = ( myDate.getMinutes() < 10 ? '0' : '' ) + myDate.getMinutes();
    const hours = ( myDate.getHours() < 10 ? '0' : '' ) + myDate.getHours();
    const seconds = ( myDate.getSeconds() < 10 ? '0' : '' ) + myDate.getSeconds();

    return `${hours}:${minutes}:${seconds}`;
  }
}
