import { Short } from '../../../models';
import { createReducer , on } from '@ngrx/store';
// import * as prescriptionsActions from '../../actions/prescriptions/prescriptions.actions';
import * as shortsActions from 'src/app/store/actions/users/shorts/shorts.actions';

export interface ShortsState {
    shorts : Short
}

let initialState : Short[] = [];

export const ShortsReducer = createReducer(
    initialState,
    on(shortsActions.getShortsSuccess , (state , {shorts}  ) => {
        
        let myState = shorts;
        return  myState ;
    }),

    on(shortsActions.addShortSuccess , (state , {short}  ) => {

        return  [...state , short] ;
        
    })
)
