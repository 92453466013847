import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { InputTrimDirective } from './directives';
import { TestComponent } from './components/test/test.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrimDirective } from './directives/inputs/input-trim.directive';



@NgModule({
  declarations: [
    /* directives */
    TrimDirective,
    TestComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    /* directives */
    TrimDirective,
    /* components */
    TestComponent
  ]
})
export class SharedModule { }
