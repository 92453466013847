import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
/* components */
import { AuthComponent } from 'src/app/auth/auth.component';
import { AppComponent } from 'src/app/app.component';
/* fontawsome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
/* ngrx */
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaEffects } from 'src/app/store/MetaEffects'; 
import { metaReducers, reducers } from 'src/app/store/Metareducers';
import { EffectsModule } from '@ngrx/effects';
/* modules */
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
/* msal configs */
import { 
  MsalModule, MsalGuard, MsalInterceptor, MSAL_INSTANCE, 
  MSAL_INTERCEPTOR_CONFIG, MsalService, MSAL_GUARD_CONFIG, MsalBroadcastService 
} from '@azure/msal-angular';
import * as msalConfigs from 'src/app/msal.configs';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    MsalModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forRoot( reducers , {metaReducers}  ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    EffectsModule.forRoot(metaEffects),
    StoreRouterConnectingModule.forRoot(),
    HttpClientModule,
    FontAwesomeModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalConfigs.MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalConfigs.MSALGuardConfigFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalConfigs.UsersMSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalConfigs.MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
