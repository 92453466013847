import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Short } from 'src/app/models';

export const addShort = createAction(
    '[Shorts] Add shorts' , 
    props<{ short:Short }>()
);

export const addShortSuccess = createAction(
    '[Shorts] Add shorts success' , 
    props<{ /* response : any */short:Short }>()
);

export const addShortFailure = createAction(
    '[Short] Add shorts failure',
    props<{ error: HttpErrorResponse }>()
);

export const getShorts = createAction(
    '[Shorts] Get shorts',
    props<{ parameters : {} }>()
);

export const getShortsSuccess = createAction(
    '[Shorts] Get shorts success',
    props<{ shorts:Short[] }>()
);

export const getShortsFailure = createAction(
    '[Shorts] Get shorts failure',
    props<{ error: HttpErrorResponse }>()
);