import { Injectable } from '@angular/core';
import { Short } from 'src/app/models';
import { FormatDatesService } from 'src/app/shared/services/helpers/format-dates.service';

@Injectable()

export class ShortenerHelpersService {

  constructor(
    private formatDatesService:                                         FormatDatesService
  ) { }

  FormatShortsTimeField(shorts : Short[]):Short[]{

    const myFormattedShorts = shorts.map(( s ) => {
      if(s.created){
        return { ...s , createdTime : this.formatDatesService.GetOnlyTime(s.created) }
      }else {
        return s;
      }
      
    })
    return myFormattedShorts
  }


}
