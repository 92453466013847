export const environment = {

    production: false,

    shortenerService : {
        url: 'https://app-shortener-dev-westeurope.azurewebsites.net/',

        clientId: '64734925-ac9c-4fb9-8910-735550240537',

        authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',

        scopes: [
            '64734925-ac9c-4fb9-8910-735550240537/shorts',
            'profile',
            'openid' 
        ],

        redirectUri: window.location.hostname === 'localhost' ? 'http://localhost:4200/' : 'https://short-dev.clinicabaviera.com/',
        logoutRedirectUri: window.location.hostname === 'localhost' ? 'http://localhost:4200/' : 'https://short-dev.clinicabaviera.com/shortener',
    }
};