/* import { appointmentsEffects } from "src/app/store/effects/appointments/appointments.effects";
import { clinicsEffects } from "src/app/store/effects/clinics/clinics.effects"; */

import { shortsEffects } from "./effects/shorts/shorts.effects";

export const metaEffects = [
    shortsEffects
    /* 
    clinicsEffects , 
    appointmentsEffects */
]